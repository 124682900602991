import React, { useEffect, useRef } from 'react';
import Section1 from './section1';
import Section8 from './section8';
import CustomCursor from './CustomCursor';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

const videoSources = [
  { src: 'video/showreelfromcue_01.mp4', type: 'video/mp4' },
];

function VideoPlayer({ src, type }) {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      const player = videojs(videoElement, {
        controls: true,
        fluid: true,
        preload: 'auto',
        sources: [{ src, type }]
      }, () => {
        console.log('player is ready');
        playerRef.current = player;
        console.log('Player dimensions:', player.currentWidth(), 'x', player.currentHeight());
        player.show();
      });

      player.on('error', (error) => {
        console.error('Video.js error:', error);
      });
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, [src, type]);

  return (
    <div data-vjs-player style={{width: '100%', height: 'auto'}}>
      <video
        ref={videoRef}
        className="video-js vjs-big-play-centered"
        playsInline
      />
    </div>
  );
}

function ShowreelFromCue() {
  return (
    <div className="showreelfromcue">
      <CustomCursor />
      <Section1 />
      <div className="project-parent">
        <div className='container'>
          <div className='project-container'>
            <h1>Showreel from CUE</h1>
            <div className="video-container">
              <VideoPlayer src={videoSources[0].src} type={videoSources[0].type} />
            </div>
            <p>Showreel from Cue. that is for you</p>
            <img className='project-image-full' src='img/showreelfromcue/showreelfromcue_01.jpg' alt="Kinon cover" />
            <div className='flex'>
              <img src='img/showreelfromcue/showreelfromcue_02.gif'/>
              <img src='img/showreelfromcue/showreelfromcue_03.gif'/>
              <img src='img/showreelfromcue/showreelfromcue_04.gif'/>
            </div>
            <img className='project-image-full' src='img/showreelfromcue/showreelfromcue_05.jpg' alt="Kinon cover" />
          </div>
          <br /><br /><br /><br /><br /><br />
        </div>
      </div>
      <Section8 />
    </div>
  );
}

export default ShowreelFromCue;