import React, { useRef, useEffect, useState } from 'react';
import './section3.css';

const Section3 = () => {
    const scrollRef = useRef(null);
    const [activeVideo, setActiveVideo] = useState(null);
    let isDown = false;
    let startX;
    let scrollLeft;

    useEffect(() => {
        const slider = scrollRef.current;
        
        const onMouseDown = (e) => {
            isDown = true;
            slider.classList.add('active');
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        };
        
        const onMouseLeave = () => {
            isDown = false;
            slider.classList.remove('active');
        };
        
        const onMouseUp = () => {
            isDown = false;
            slider.classList.remove('active');
        };
        
        const onMouseMove = (e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = (x - startX) * 3; //scroll-fast
            slider.scrollLeft = scrollLeft - walk;
        };

        slider.addEventListener('mousedown', onMouseDown);
        slider.addEventListener('mouseleave', onMouseLeave);
        slider.addEventListener('mouseup', onMouseUp);
        slider.addEventListener('mousemove', onMouseMove);

        // New event listeners for video play/pause
        const scrollItems = slider.querySelectorAll('.scroll-item');
        scrollItems.forEach(item => {
            const video = item.querySelector('video');
            
            item.addEventListener('mouseenter', () => {
                if (video && video !== activeVideo) {
                    if (activeVideo) {
                        activeVideo.pause();
                        activeVideo.currentTime = 0;
                    }
                    setActiveVideo(video);
                    video.play().catch(e => console.error("Error playing video:", e));
                }
            });
        });

        return () => {
            slider.removeEventListener('mousedown', onMouseDown);
            slider.removeEventListener('mouseleave', onMouseLeave);
            slider.removeEventListener('mouseup', onMouseUp);
            slider.removeEventListener('mousemove', onMouseMove);
            
            // Remove video event listeners
            scrollItems.forEach(item => {
                item.removeEventListener('mouseenter', () => {});
            });
        };
    }, [activeVideo]);

    useEffect(() => {
        return () => {
            if (activeVideo) {
                activeVideo.pause();
                activeVideo.currentTime = 0;
            }
        };
    }, [activeVideo]);

    return (
        <div className='section3' id='sec3'>
            <div className='column-gap'></div>
            <div className='container'>
                <div className='title1'>
                    Motion Graphics
                </div>
            </div>
            <div className='video-scroll' ref={scrollRef}>
                <div className='scroll-item'>
                    <img src='img/video_01.png' alt="Gallery Image 1" />
                    <video src='video/video_01.mp4' loop muted></video>
                </div>
                <div className='scroll-item up'>
                    <img src='img/video_02.png' alt="Gallery Image 2" />
                    <video src='video/video_02.mp4' loop muted></video>
                </div>
                <div className='scroll-item'>
                    <img src='img/video_03.png' alt="Gallery Image 3" />
                    <video src='video/video_03.mp4' loop muted></video>
                </div>
                <div className='scroll-item up'>
                    <img src='img/video_04.png' alt="Gallery Image 4" />
                    <video src='video/video_04.mp4' loop muted></video>
                </div>
                <div className='scroll-item'>
                    <img src='img/video_05.png' alt="Gallery Image 5" />
                    <video src='video/video_05.mp4' loop muted></video>
                </div>
                <div className='scroll-item up'>
                    <img src='img/video_06.png' alt="Gallery Image 6" />
                    <video src='video/video_06.mp4' loop muted></video>
                </div>
                <div className='scroll-item'>
                    <img src='img/video_07.png' alt="Gallery Image 7" />
                    <video src='video/video_07.mp4' loop muted></video>
                </div>
                <div className='scroll-item up'>
                    <img src='img/video_08.png' alt="Gallery Image 8" />
                    <video src='video/video_08.mp4' loop muted></video>
                </div>
            </div>
        </div>
    );
};

export default Section3;