import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Section1 from './components/section1';
import Section2 from './components/section2';
import Section3 from './components/section3';
import Section4 from './components/section4';
import Section5 from './components/section5';
import Section6 from './components/section6';
import Section7 from './components/section7';
import Section8 from './components/section8';
import CustomCursor from './components/CustomCursor';
import Kinon from './components/kinon';
import Headspace from './components/headspace';
import Helloworld from './components/helloworld';
import ShowreelFromCue from './components/showreelfromcue';
import Deardiary from './components/deardiary';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <CustomCursor />
        <Routes>
          <Route path="/" element={
            <>
              <Section1 />
              <Section2 />
              <Section3 />
              <Section4 />
              <Section5 />
              <Section6 />
              <Section7 />
              <Section8 />
            </>
          } />
          <Route path="/kinon" element={<Kinon />} />
          <Route path="/headspace" element={<Headspace />} />
          <Route path="/helloworld" element={<Helloworld />} />
          <Route path="/showreelfromcue" element={<ShowreelFromCue />} />
          <Route path="/deardiary" element={<Deardiary />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;