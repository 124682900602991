import React from 'react';
import './section6.css';

const Section6 = () => {
    return (
        <div className='section6'>
            <div class='clipper'>
            <div className='line-word'>
                <div class='loop-text'>GORIES CATE</div>
                <div class='loop-text'>GORIES CATE</div>
                <div class='loop-text'>GORIES CATE</div>
                <div class='loop-text'>GORIES CATE</div>
                <div class='loop-text'>GORIES CATE</div>
                <div class='loop-text'>GORIES CATE</div>
                <div class='loop-text'>GORIES CATE</div>
                <div class='loop-text'>GORIES CATE</div>
                <div class='loop-text'>GORIES CATE</div>
                <div class='loop-text'>GORIES CATE</div>
            </div>
            <div class='line-categories'>
                <div class='category-item'>
                    <p>Motion</p>
                    <p>Illustration</p>
                    <p>Print ad</p>
                    <p>TV Commercial</p>
                    <p>Poster</p>
                </div>
                <div class='category-item'>
                    <p>Motion</p>
                    <p>Illustration</p>
                    <p>Print ad</p>
                    <p>TV Commercial</p>
                    <p>Poster</p>
                </div>
                <div class='category-item'>
                    <p>Motion</p>
                    <p>Illustration</p>
                    <p>Print ad</p>
                    <p>TV Commercial</p>
                    <p>Poster</p>
                </div>
            </div>
            </div>
        </div>
    );
};

export default Section6;
