import React, { useRef, useEffect } from 'react';
import './section4.css';

const Section4 = () => {
  const scrollRef = useRef(null);

  useEffect(() => {
    const scrollContainer = scrollRef.current;
    if (!scrollContainer) return;

    const scrollWidth = scrollContainer.scrollWidth;
    const animationDuration = scrollWidth / 100; // Adjust speed as needed

    scrollContainer.style.setProperty('--scroll-width', `${scrollWidth}px`);
    scrollContainer.style.setProperty('--animation-duration', `${animationDuration}s`);

    scrollContainer.classList.add('scrolling');

    return () => {
      scrollContainer.classList.remove('scrolling');
    };
  }, []);

  return (
    <div className='section4'>
      <div className='column-gap'></div>
      <div className='container'>
        <div className='title2'>
          Illustrations
        </div>
      </div>
        <div className='scroll-container' ref={scrollRef}>
          <div className='scroll-content'>
              <div className='scroll-object'>
                <div className='scroll-child lay1'>
                  <img src='img/illustration_01.png'/>
                </div>
              </div>
              <div className='scroll-object'>
                <div className='scroll-child lay2'>
                  <img src='img/illustration_02.png'/>
                </div>
                <div className='scroll-child lay3'>
                  <img src='img/illustration_03.png'/>
                </div>
              </div>
              <div className='scroll-object'>
                <div className='scroll-child lay1'>
                  <img src='img/illustration_04.png'/>
                </div>
            </div>
            <div className='scroll-object'>
                <div className='scroll-child lay4'>
                  <img src='img/illustration_05.png'/>
                </div>
                <div className='scroll-child lay4'>
                  <img src='img/illustration_06.png'/>
                </div>
            </div>
            <div className='scroll-object'>
                <div className='scroll-child lay1'>
                  <img src='img/illustration_10.png'/>
                </div>
            </div>
            <div className='scroll-object'>
                <div className='scroll-child lay2'>
                  <img src='img/illustration_08.png'/>
                </div>
                <div className='scroll-child lay3'>
                  <img src='img/illustration_09.png'/>
                </div>
            </div>
          </div>
          
          <div className='scroll-content'>
              <div className='scroll-object'>
                <div className='scroll-child lay1'>
                  <img src='img/illustration_01.png'/>
                </div>
              </div>
              <div className='scroll-object'>
                <div className='scroll-child lay2'>
                  <img src='img/illustration_02.png'/>
                </div>
                <div className='scroll-child lay3'>
                  <img src='img/illustration_03.png'/>
                </div>
              </div>
              <div className='scroll-object'>
                <div className='scroll-child lay1'>
                  <img src='img/illustration_04.png'/>
                </div>
            </div>
            <div className='scroll-object'>
                <div className='scroll-child lay4'>
                  <img src='img/illustration_05.png'/>
                </div>
                <div className='scroll-child lay4'>
                  <img src='img/illustration_06.png'/>
                </div>
            </div>
            <div className='scroll-object'>
                <div className='scroll-child lay1'>
                  <img src='img/illustration_10.png'/>
                </div>
            </div>
            <div className='scroll-object'>
                <div className='scroll-child lay2'>
                  <img src='img/illustration_08.png'/>
                </div>
                <div className='scroll-child lay3'>
                  <img src='img/illustration_09.png'/>
                </div>
            </div>
          </div>
        </div>
        <br/><br/><br/>
      </div>
  );
};

export default Section4;