import React from 'react';
import './section2.css';
import { Link } from 'react-router-dom';

const Section2 = () => {
    return (
        <div className='section2'>
            
            <div className='hero-banner'>
                <iframe src='https://my.spline.design/websiteherobanner-872b9ce672f1ac6e79f1add70c4e5651/' frameBorder='0' width='100%' height='900px' title="Hero Banner"></iframe>
            </div>
            <div className='line'></div>
            <Link to="/kinon">
            <div class='select'>
            <div className='container'>
                <div className='selection'>
                    <div className='selection-left'>
                        <div className='selection-order'>
                            01
                        </div>
                        <div className='selection-title'>
                            Kinon
                        </div>
                        <div className='selection-image' style={{backgroundImage: "url('img/select_image_01.png')"}}>
                        </div>
                    </div>
                    <div className='selection-right'>
                        <div className='see-more-sign'>
                            <svg width="19" height="15" viewBox="0 0 19 15" xmlns="http://www.w3.org/2000/svg">
<path d="M12.5857 14.4769C12.451 14.3421 12.3753 14.1593 12.3753 13.9688C12.3753 13.7782 12.451 13.5954 12.5857 13.4606L16.3901 9.65625H8.7814C4.01825 9.65625 0.156403 5.79441 0.156403 1.03125C0.156403 0.840627 0.232126 0.657809 0.366919 0.523018C0.501711 0.388226 0.684528 0.312501 0.875153 0.312501C1.06578 0.312501 1.24859 0.388226 1.38338 0.523018C1.51818 0.657809 1.5939 0.840627 1.5939 1.03125C1.5939 5.00091 4.81175 8.21875 8.7814 8.21875H16.3901L12.5857 4.41441C12.4548 4.27885 12.3824 4.09729 12.384 3.90884C12.3857 3.72038 12.4612 3.54011 12.5945 3.40685C12.7278 3.27359 12.908 3.198 13.0965 3.19636C13.2849 3.19472 13.4665 3.26717 13.6021 3.39809L18.6333 8.42934C18.7681 8.56413 18.8438 8.74691 18.8438 8.9375C18.8438 9.12809 18.7681 9.31087 18.6333 9.44566L13.6021 14.4769C13.4673 14.6117 13.2845 14.6873 13.0939 14.6873C12.9033 14.6873 12.7205 14.6117 12.5857 14.4769Z"/>
</svg>

                        </div>
                        <div className='see-more-text'>
                            See more
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </Link>
            <div className='line'></div>
            <Link to="/headspace">
            <div class='select'>
            <div className='container'>
                <div className='selection'>
                    <div className='selection-left'>
                        <div className='selection-order'>
                            02
                        </div>
                        <div className='selection-title'>
                            Headspace
                        </div>
                        <div className='selection-image' style={{backgroundImage: "url('img/select_image_02.png')"}}>
                        </div>
                    </div>
                    <a href='#'>
                    <div className='selection-right'>
                        <div className='see-more-sign'>
                            <svg width="19" height="15" viewBox="0 0 19 15" xmlns="http://www.w3.org/2000/svg">
<path d="M12.5857 14.4769C12.451 14.3421 12.3753 14.1593 12.3753 13.9688C12.3753 13.7782 12.451 13.5954 12.5857 13.4606L16.3901 9.65625H8.7814C4.01825 9.65625 0.156403 5.79441 0.156403 1.03125C0.156403 0.840627 0.232126 0.657809 0.366919 0.523018C0.501711 0.388226 0.684528 0.312501 0.875153 0.312501C1.06578 0.312501 1.24859 0.388226 1.38338 0.523018C1.51818 0.657809 1.5939 0.840627 1.5939 1.03125C1.5939 5.00091 4.81175 8.21875 8.7814 8.21875H16.3901L12.5857 4.41441C12.4548 4.27885 12.3824 4.09729 12.384 3.90884C12.3857 3.72038 12.4612 3.54011 12.5945 3.40685C12.7278 3.27359 12.908 3.198 13.0965 3.19636C13.2849 3.19472 13.4665 3.26717 13.6021 3.39809L18.6333 8.42934C18.7681 8.56413 18.8438 8.74691 18.8438 8.9375C18.8438 9.12809 18.7681 9.31087 18.6333 9.44566L13.6021 14.4769C13.4673 14.6117 13.2845 14.6873 13.0939 14.6873C12.9033 14.6873 12.7205 14.6117 12.5857 14.4769Z"/>
</svg>

                        </div>
                        <div className='see-more-text'>
                            See more
                        </div>
                    </div>
                    </a>
                </div>
            </div>
            <div className='hover-up'></div>
            </div>
            </Link>
            <div className='line'></div>
            <Link to="/helloworld">
            <div class='select'>
            <div className='container'>
                <div className='selection'>
                    <div className='selection-left'>
                        <div className='selection-order'>
                            03
                        </div>
                        <div className='selection-title'>
                            Hello world
                        </div>
                        <div className='selection-image' style={{backgroundImage: "url('img/select_image_03.png')"}}>
                        </div>
                    </div>
                    <a href='#'>
                    <div className='selection-right'>
                        <div className='see-more-sign'>
                            <svg width="19" height="15" viewBox="0 0 19 15" xmlns="http://www.w3.org/2000/svg">
<path d="M12.5857 14.4769C12.451 14.3421 12.3753 14.1593 12.3753 13.9688C12.3753 13.7782 12.451 13.5954 12.5857 13.4606L16.3901 9.65625H8.7814C4.01825 9.65625 0.156403 5.79441 0.156403 1.03125C0.156403 0.840627 0.232126 0.657809 0.366919 0.523018C0.501711 0.388226 0.684528 0.312501 0.875153 0.312501C1.06578 0.312501 1.24859 0.388226 1.38338 0.523018C1.51818 0.657809 1.5939 0.840627 1.5939 1.03125C1.5939 5.00091 4.81175 8.21875 8.7814 8.21875H16.3901L12.5857 4.41441C12.4548 4.27885 12.3824 4.09729 12.384 3.90884C12.3857 3.72038 12.4612 3.54011 12.5945 3.40685C12.7278 3.27359 12.908 3.198 13.0965 3.19636C13.2849 3.19472 13.4665 3.26717 13.6021 3.39809L18.6333 8.42934C18.7681 8.56413 18.8438 8.74691 18.8438 8.9375C18.8438 9.12809 18.7681 9.31087 18.6333 9.44566L13.6021 14.4769C13.4673 14.6117 13.2845 14.6873 13.0939 14.6873C12.9033 14.6873 12.7205 14.6117 12.5857 14.4769Z"/>
</svg>

                        </div>
                        <div className='see-more-text'>
                            See more
                        </div>
                    </div>
                    </a>
                </div>
            </div>
            </div>
            </Link>
            <div className='line'></div>
            <Link to="/showreelfromcue">
            <div class='select'>
            <div className='container'>
                <div className='selection'>
                    <div className='selection-left'>
                        <div className='selection-order'>
                            04
                        </div>
                        <div className='selection-title'>
                            Showreel from CUE
                        </div>
                        <div className='selection-image' style={{backgroundImage: "url('img/select_image_04.png')"}}>
                        </div>
                    </div>
                    <div className='selection-right'>
                        <div className='see-more-sign'>
                            <svg width="19" height="15" viewBox="0 0 19 15" xmlns="http://www.w3.org/2000/svg">
<path d="M12.5857 14.4769C12.451 14.3421 12.3753 14.1593 12.3753 13.9688C12.3753 13.7782 12.451 13.5954 12.5857 13.4606L16.3901 9.65625H8.7814C4.01825 9.65625 0.156403 5.79441 0.156403 1.03125C0.156403 0.840627 0.232126 0.657809 0.366919 0.523018C0.501711 0.388226 0.684528 0.312501 0.875153 0.312501C1.06578 0.312501 1.24859 0.388226 1.38338 0.523018C1.51818 0.657809 1.5939 0.840627 1.5939 1.03125C1.5939 5.00091 4.81175 8.21875 8.7814 8.21875H16.3901L12.5857 4.41441C12.4548 4.27885 12.3824 4.09729 12.384 3.90884C12.3857 3.72038 12.4612 3.54011 12.5945 3.40685C12.7278 3.27359 12.908 3.198 13.0965 3.19636C13.2849 3.19472 13.4665 3.26717 13.6021 3.39809L18.6333 8.42934C18.7681 8.56413 18.8438 8.74691 18.8438 8.9375C18.8438 9.12809 18.7681 9.31087 18.6333 9.44566L13.6021 14.4769C13.4673 14.6117 13.2845 14.6873 13.0939 14.6873C12.9033 14.6873 12.7205 14.6117 12.5857 14.4769Z"/>
</svg>

                        </div>
                        <div className='see-more-text'>
                            See more
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </Link>
            <div className='line'></div>
            <Link to="/deardiary">
            <div class='select'>
            <div className='container'>
                <div className='selection'>
                    <div className='selection-left'>
                        <div className='selection-order'>
                            05
                        </div>
                        <div className='selection-title'>
                            Dear Diary
                        </div>
                        <div className='selection-image' style={{backgroundImage: "url('img/select_image_05.png')"}}>
                        </div>
                    </div>
                    <div className='selection-right'>
                        <div className='see-more-sign'>
                            <svg width="19" height="15" viewBox="0 0 19 15" xmlns="http://www.w3.org/2000/svg">
<path d="M12.5857 14.4769C12.451 14.3421 12.3753 14.1593 12.3753 13.9688C12.3753 13.7782 12.451 13.5954 12.5857 13.4606L16.3901 9.65625H8.7814C4.01825 9.65625 0.156403 5.79441 0.156403 1.03125C0.156403 0.840627 0.232126 0.657809 0.366919 0.523018C0.501711 0.388226 0.684528 0.312501 0.875153 0.312501C1.06578 0.312501 1.24859 0.388226 1.38338 0.523018C1.51818 0.657809 1.5939 0.840627 1.5939 1.03125C1.5939 5.00091 4.81175 8.21875 8.7814 8.21875H16.3901L12.5857 4.41441C12.4548 4.27885 12.3824 4.09729 12.384 3.90884C12.3857 3.72038 12.4612 3.54011 12.5945 3.40685C12.7278 3.27359 12.908 3.198 13.0965 3.19636C13.2849 3.19472 13.4665 3.26717 13.6021 3.39809L18.6333 8.42934C18.7681 8.56413 18.8438 8.74691 18.8438 8.9375C18.8438 9.12809 18.7681 9.31087 18.6333 9.44566L13.6021 14.4769C13.4673 14.6117 13.2845 14.6873 13.0939 14.6873C12.9033 14.6873 12.7205 14.6117 12.5857 14.4769Z"/>
</svg>

                        </div>
                        <div className='see-more-text'>
                            See more
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </Link>
            <div className='line'></div>
        </div>
    );
};

export default Section2;
