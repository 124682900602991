import React, { useEffect, useRef } from 'react';
import Matter from 'matter-js';

const GravityPills = () => {
  const sceneRef = useRef(null);
  const engineRef = useRef(null);

  useEffect(() => {
    const Engine = Matter.Engine;
    const Render = Matter.Render;
    const World = Matter.World;
    const Bodies = Matter.Bodies;
    const Composite = Matter.Composite;
    const Events = Matter.Events;

    engineRef.current = Engine.create();

    const render = Render.create({
      element: sceneRef.current,
      engine: engineRef.current,
      options: {
        width: 600,
        height: 800,
        wireframes: false,
        background: 'transparent'
      }
    });

    engineRef.current.world.gravity.y = 0.1;

    // Pill özellikleri
    const pills = [
      { name: 'logo1', width: 300, height: 90, color: '#FF6B6B' },
      { name: 'logo2', width: 200, height: 90, color: '#4ECDC4' },
      { name: 'logo3', width: 250, height: 90, color: '#45B7D1' },
      { name: 'logo4', width: 300, height: 90, color: '#FFA07A' },
      { name: 'logo5', width: 300, height: 90, color: '#98D8C8' },
      { name: 'logo6', width: 250, height: 90, color: '#F06292' },
      { name: 'logo7', width: 250, height: 90, color: '#AED581' },
      { name: 'logo8', width: 300, height: 90, color: '#7986CB' },
      { name: 'logo9', width: 300, height: 90, color: '#FFD54F' },
      { name: 'logo10', width: 200, height: 90, color: '#4DB6AC' },
      { name: 'logo11', width: 300, height: 90, color: '#BA68C8' },
      { name: 'logo12', width: 300, height: 90, color: '#FFB74D' },
      { name: 'logo13', width: 300, height: 90, color: '#81C784' }
    ];

    // Her bir pili ayrı ayrı oluştur
    const createPill = (pill, x, y) => {
      const body = Bodies.rectangle(x, y, pill.width, pill.height, {
        chamfer: { radius: pill.height / 2, quality: 16 },
        restitution: 0.1,
        friction: 0.005,
        render: {
          sprite: {
            texture: `logos/${pill.name}.png`,
            xScale: 0.5,
            yScale: 0.5
          }
        },
        label: pill.name
      });
      return body;
    };

    const pillBodies = pills.map((pill, index) => {
      const x = 100 + (index % 4) * 200;
      const y = 50 + Math.floor(index / 4) * 150;
      return createPill(pill, x, y);
    });

    World.add(engineRef.current.world, pillBodies);

    // Update boundaries to match canvas dimensions
    const wallThickness = 60;
    const ground = Bodies.rectangle(render.options.width / 2, render.options.height, render.options.width, wallThickness, { isStatic: true, render: { visible: false } });
    const leftWall = Bodies.rectangle(0, render.options.height / 2, wallThickness, render.options.height, { isStatic: true, render: { visible: false } });
    const rightWall = Bodies.rectangle(render.options.width, render.options.height / 2, wallThickness, render.options.height, { isStatic: true, render: { visible: false } });
    const ceiling = Bodies.rectangle(render.options.width / 2, 0, render.options.width, wallThickness, { isStatic: true, render: { visible: false } });
    World.add(engineRef.current.world, [ground, leftWall, rightWall, ceiling]);

    // Mouse hover itme kuvveti için event listener
    render.canvas.addEventListener('mousemove', (event) => {
      const mousePosition = {
        x: event.offsetX,
        y: event.offsetY
      };

      pillBodies.forEach((pill) => {
        const distance = Matter.Vector.magnitude(Matter.Vector.sub(mousePosition, pill.position));
        if (distance < 300) {
          const force = Matter.Vector.mult(
            Matter.Vector.normalise(Matter.Vector.sub(pill.position, mousePosition)),
            0.005 * pill.mass * (1 - distance / 300)
          );
          Matter.Body.applyForce(pill, pill.position, force);
        }
      });
    });

    // Özel render fonksiyonu
    const customRender = () => {
      const context = render.context;
      const bodies = Composite.allBodies(engineRef.current.world);

      context.beginPath();

      for (let i = 0; i < bodies.length; i += 1) {
        const body = bodies[i];
        if (body.render.sprite && body.render.sprite.texture) {
          const { x, y } = body.position;
          const { width, height } = body.bounds;
          const angle = body.angle;

          context.save();
          context.translate(x, y);
          context.rotate(angle);

          const image = new Image();
          image.src = body.render.sprite.texture;
          context.drawImage(image, -width / 2, -height / 2, width, height);

          context.restore();
        }
      }

      context.lineWidth = 1;
      context.strokeStyle = '#000';
      context.stroke();
    };

    // Özel render fonksiyonunu ekleyin
    Events.on(render, 'afterRender', customRender);

    Render.run(render);
    const runner = Matter.Runner.create();
    Matter.Runner.run(runner, engineRef.current);

    return () => {
      Render.stop(render);
      World.clear(engineRef.current.world);
      Engine.clear(engineRef.current);
      render.canvas.remove();
      render.canvas = null;
      render.context = null;
      render.textures = {};
    };
  }, []);

  return <div ref={sceneRef} style={{ width: '600px', height: '800px' }} />;
};

export default GravityPills;