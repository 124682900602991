import React from 'react';
import { Link } from 'react-scroll';
import './section1.css';

const Section1 = () => {
    return (
        <div className="section1">
            <div className="container">
                <div className="navbar">
                        <a href='/'>
                        <div className="logo">
                            <img src='img/nijat-logo.svg' alt="Nijat Logo" />
                        </div>
                        </a>
                    <div className="menu">
                        <Link to="sec3" smooth={true} duration={1000}>
                            <div className="menu-item">Works</div>
                        </Link>
                        <Link to="sec7" smooth={true} duration={1000}>
                            <div className="menu-item">About me</div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Section1;