import React from 'react';
import './section5.css';

const Section5 = () => {
    return (
        <div className='section5'>
            <div className='column-gap'></div>
            <div className='container'>
                <div className='print-parent'>
                    <div className='print-row'>
                        <div className='print-image'>
                            <img src='img/print_01.png'></img>
                        </div>
                        <div className='print-text'>Some</div>
                        <div className='print-image'>
                            <img src='img/print_02.png'></img>
                        </div>
                        <div className='print-image'>
                            <img src='img/print_03.png'></img>
                        </div>
                    </div>
                    <div className='print-row'>
                        <div className='print-image flex1'>
                            <img src='img/print_04.png'></img>
                        </div>
                        <div className='print-image flex2'>
                            <img src='img/print_05.png'></img>
                        </div>
                        <div className='print-text'>Print</div>
                        <div className='print-image flex15'>
                            <img src='img/print_06.png'></img>
                        </div>
                    </div>
                    <div className='print-row'>
                        <div className='print-image flex2'>
                            <img src='img/print_07.png'></img>
                        </div>
                        <div className='print-text'>Works</div>
                        <div className='print-image flex1'>
                            <img src='img/print_08.png'></img>
                        </div>
                        <div className='print-image flex2'>
                            <img src='img/print_09.png'></img>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Section5;
