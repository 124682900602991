import React from 'react';
import './section8.css';

const Section8 = () => {
    return (
        <div className='section8'>
            <div class='orange-line'></div>
            <div class='container'>
                <div class='footer-items'>
                    <div class='footer-left'>
                        <a href='https://www.linkedin.com/in/nijat-ahmadzada-3b6946130/' target="_blank">
                        <div class='footer-left-child'>Linkedin</div></a>
                        <div class='vertical-seperator'></div>
                        <a href='https://www.instagram.com/n.ahmadzada/' target="_blank">
                        <div class='footer-left-child'>Instagram</div></a>
                        <div class='vertical-seperator'></div>
                        <a href='https://www.behance.net/ahmadzada' target="_blank">
                        <div class='footer-left-child'>Behance</div></a>
                    </div>
                    <a href="mailto:hello@nijatahmadzada.com">
                    <div class='footer-right'>
                    
                        <div class='contact-me-icon'>
                            <img src='img/contact_arrow.svg'/>
                        </div>
                        <div class='footer-contact-me'>hello@nijatahmadzada.com</div>
                        
                    </div>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Section8;
