import React from 'react';
import Section1 from './section1';
import Section8 from './section8';
import CustomCursor from './CustomCursor';

function Helloworld() {
  return (
    <div className="helloworld">
      <CustomCursor />
      <Section1 />
      <div className="project-parent">
        <div className='container'>
          <div className='project-container'>
          <h1>Hello World</h1>
            <img className='project-image-full' src='img/helloworld/001_helloworld.jpg' alt="hello world cover" />
            <p>First we illustrated 2d and then created 3d, we loved this concept. Hope you like too ♥</p>
            <div className='flex'>
              <img className='project-image-full' src='img/helloworld/002_helloworld.jpg' alt="hello world about" />
              <img className='project-image-full' src='img/helloworld/003_helloworld.jpg' alt="hello world examples" />
              <img className='project-image-full' src='img/helloworld/004_helloworld.jpg' alt="hello world hero banner animation" />
            </div>
            <p>Process</p>
            <img className='project-image-full' src='img/helloworld/005_helloworld.jpg' alt="hello world illustration sketches" />
            <img className='project-image-full' src='img/helloworld/006_helloworld.jpg' alt="hello world article page design" />
            <img className='project-image-full' src='img/helloworld/007_helloworld.gif' alt="hello world presentation ending animation" />
            <p>Clay renders</p>
            <div className='flex'>
              <img className='project-image-full' src='img/helloworld/008_helloworld.jpg' alt="hello world article page design" />
              <img className='project-image-full' src='img/helloworld/009_helloworld.jpg' alt="hello world article page design" />
              <img className='project-image-full' src='img/helloworld/010_helloworld.jpg' alt="hello world article page design" />
            </div>
            <p>Close ups</p>
            <div className='flex'>
              <img className='project-image-full' src='img/helloworld/011_helloworld.jpg' alt="hello world article page design" />
              <img className='project-image-full' src='img/helloworld/012_helloworld.jpg' alt="hello world article page design" />
              <img className='project-image-full' src='img/helloworld/013_helloworld.jpg' alt="hello world article page design" />
            </div>
          </div>
          <br /><br /><br /><br /><br /><br />
        </div>
      </div>
      <Section8 />
    </div>
  );
}

export default Helloworld;