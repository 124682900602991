import React from 'react';
import './section7.css';
import GravityCircles from './GravityCircles';

const Section7 = () => {
    return (
        <div className='section7' id='sec7'>
            <div className='container'>
                <div class='about-all'>
                <div class='about-paragraph'>
                    <div className='title1'>
                        About
                    </div>
                    <p class='about_text'>
                    I'm Nijat Ahmadzada, a passionate <span>Art Director</span> and <span>3D Artist</span> with 9 years of experience in the advertising industry. My journey began as a Graphic Designer, a role I embraced for about 4-5 years before moving to <span>Turkey</span>. There, I expanded my horizons, working as an Art Director and 3D Artist in various agencies.
                    <br/><br/>
I'm driven by a love for exploring different creative avenues and pushing the boundaries of conventional design. I thrive on experimenting with <span>new techniques</span> and bringing unique, innovative ideas to life. Additionally, I enjoy merging <span>coding</span> with design to streamline and differentiate my daily tasks, always aiming to create something extraordinary.
                    </p>
                </div>
                <div class='floating-logos'>
                <GravityCircles />
                </div>
                </div>
            </div>
        </div>
    );
};

export default Section7;
