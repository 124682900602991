import React from 'react';
import Section1 from './section1';
import Section8 from './section8';
import CustomCursor from './CustomCursor';

function Headspace() {
  return (
    <div className="headspace">
      <CustomCursor />
      <Section1 />
      <div className="project-parent">
        <div className='container'>
          <div className='project-container'>
            <img className='project-image-full' src='img/headspace/001_headspace.gif' alt="Headspace cover" />
            <img className='project-image-full' src='img/headspace/002_headspace.jpg' alt="Headspace about" />
            <img className='project-image-full' src='img/headspace/003_headspace.jpg' alt="Headspace examples" />
            <img className='project-image-full' src='img/headspace/004_headspace.gif' alt="Headspace hero banner animation" />
            <img className='project-image-full' src='img/headspace/005_headspace.jpg' alt="Headspace illustration sketches" />
            <img className='project-image-full' src='img/headspace/006_headspace.jpg' alt="Headspace article page design" />
            <img className='project-image-full' src='img/headspace/007_headspace.gif' alt="Headspace presentation ending animation" />
          </div>
          <br /><br /><br /><br /><br /><br />
        </div>
      </div>
      <Section8 />
    </div>
  );
}

export default Headspace;