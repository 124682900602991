import React from 'react';
import Section1 from './section1';
import Section8 from './section8';
import CustomCursor from './CustomCursor';

function Deardiary() {
  return (
    <div className="deardiary">
      <CustomCursor />
      <Section1 />
      <div className="project-parent">
        <div className='container'>
          <div className='project-container'>
          <h1>Dear Diary</h1>
            <img className='project-image-full' src='img/deardiary/deardiary_01.jpg' alt="hello world cover" />
            <p>During a creative phase, I shared a series of 3D illustrations
               on Instagram. I am now pleased to present some of those visuals to you.</p>
            <div className='flex'>
              <img className='project-image-full' src='img/deardiary/deardiary_02.jpg'/>
              <img className='project-image-full' src='img/deardiary/deardiary_03.jpg'/>
              <img className='project-image-full' src='img/deardiary/deardiary_04.jpg'/>
            </div>
            <img className='project-image-full' src='img/deardiary/deardiary_05.jpg'/>
            <img className='project-image-full' src='img/deardiary/deardiary_06.jpg'/>
            <img className='project-image-full' src='img/deardiary/deardiary_07.jpg'/>
            <div className='flex'>
              <img className='project-image-full' src='img/deardiary/deardiary_08.jpg'/>
              <img className='project-image-full' src='img/deardiary/deardiary_09.jpg' />
              <img className='project-image-full' src='img/deardiary/deardiary_10.jpg' />
            </div>
            <img className='project-image-full' src='img/deardiary/deardiary_11.jpg' />
            <div className='flex'>
              
              <img className='project-image-full' src='img/deardiary/deardiary_12.jpg' />
              <img className='project-image-full' src='img/deardiary/deardiary_13.jpg' />
            </div>
          </div>
          <br /><br /><br /><br /><br /><br />
        </div>
      </div>
      <Section8 />
    </div>
  );
}

export default Deardiary;