import React, { useEffect, useRef } from 'react';
import Section1 from './section1';
import Section8 from './section8';
import CustomCursor from './CustomCursor';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

const videoSources = [
  { src: 'video/kinon_01.mp4', type: 'video/mp4' },
  { src: 'video/kinon_02.mp4', type: 'video/mp4' },
  { src: 'video/kinon_03.mp4', type: 'video/mp4' },
  { src: 'video/kinon_04.mp4', type: 'video/mp4' },
];

function VideoPlayer({ src, type }) {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      const player = videojs(videoElement, {
        controls: true,
        fluid: true,
        preload: 'auto',
        sources: [{ src, type }]
      }, () => {
        console.log('player is ready');
        playerRef.current = player;
        console.log('Player dimensions:', player.currentWidth(), 'x', player.currentHeight());
        player.show();
      });

      player.on('error', (error) => {
        console.error('Video.js error:', error);
      });
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, [src, type]);

  return (
    <div data-vjs-player style={{width: '100%', height: 'auto'}}>
      <video
        ref={videoRef}
        className="video-js vjs-big-play-centered"
        playsInline
      />
    </div>
  );
}

function Kinon() {
  return (
    <div className="kinon">
      <CustomCursor />
      <Section1 />
      <div className="project-parent">
        <div className='container'>
          <div className='project-container'>
            <h1>KINON</h1>
            <p>We are pleased to present the 3D characters and icon packs developed for "Kinon," a film and video platform launched by Azercell, Azerbaijan's local telecommunications company. These characters are integral to the platform's identity and are featured in its promotional films, advertising materials, and as profile pictures within the platform. Our project showcases the creative process and the final designs, highlighting how these elements enhance the user experience and brand communication for "Kinon." Explore our work to see how we bring the platform to life through engaging and visually appealing 3D art.</p>
            <img className='project-image-full' src='img/kinon/001_cover.png' alt="Kinon cover" />
            <h2>Sketches</h2>
            <img className='project-image-full' src='img/kinon/002_sketches.png' alt="Kinon sketches" />
            <h2>Character evolution</h2>
            <img className='project-image-full' src='img/kinon/003_sketchto3d.png' alt="Character evolution" />
            <h2>T-pose</h2>
            <img className='project-image-full' src='img/kinon/004_tpose.png' alt="T-pose" />
            <h2>User avatars</h2>
            <img className='project-image-full' src='img/kinon/005_profile_pictures.png' alt="User avatars" />
            <h2>Icon pack</h2>
            <img className='project-image-full' src='img/kinon/006_icon_pack.png' alt="Icon pack" />
            <h2>Teaser videos</h2>
            <div className="video-container">
              <VideoPlayer src={videoSources[0].src} type={videoSources[0].type} />
            </div>
            <br />
            <div className="video-container">
              <VideoPlayer src={videoSources[1].src} type={videoSources[1].type} />
            </div>
            <br />
            <div className="video-container">
              <VideoPlayer src={videoSources[2].src} type={videoSources[2].type} />
            </div>
            <br />
            <div className="video-container">
              <VideoPlayer src={videoSources[3].src} type={videoSources[3].type} />
            </div>
          </div>
          <br /><br /><br /><br /><br /><br />
        </div>
      </div>
      <Section8 />
    </div>
  );
}

export default Kinon;